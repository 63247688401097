// Custom Theming Section
@use '@angular/material' as mat;
@use 'palette-helper';

@include mat.core();

$custom-background-color: black;
$aurum-palette: (
  50: #fbf7e3,
  100: #f6ebb8,
  200: #f1de8c,
  300: #ecd361,
  400: #e9c846,
  500: #e7be33,
  600: #e7b22d,
  700: #e6a126,
  800: #e5911f,
  900: #0A4579,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  )
);

// Setup theme
$my-primary: mat.define-palette(mat.$yellow-palette, A200);
$my-accent: mat.define-palette($aurum-palette, 300);
$my-warn: mat.define-palette($aurum-palette, 900);

$my-theme: mat.define-light-theme((color: (primary: $my-primary, accent: $my-accent, warn: $my-warn)));
$my-dark-theme: mat.define-dark-theme((color: (primary: $my-primary, accent: $my-accent, warn: $my-warn)));
$my-dark-theme: palette-helper.modify-background($my-dark-theme, $custom-background-color);

@include mat.all-component-themes($my-dark-theme);
@include mat.dialog-color($my-theme);
@include mat.select-color($my-theme);
@include mat.option-color($my-theme);
@include mat.menu-color($my-theme);

@import '@angular/material/theming';
$custom-typography: mat.define-typography-config($font-family: "mont-regular");
@include mat.all-component-typographies($custom-typography);

// Select
.mat-select-value-text {
    @apply text-white;
}

.mat-select-arrow-wrapper {
    height: 20px !important;
    width: 20px !important;
    display: block !important;
    @apply mr-2;
}

.mat-select-arrow {
    height: 15px !important;
    width: 15px !important;
    border-width: 0 !important;
    margin: 0 !important;
    @apply gsc-icon-xs bg-gsc-icon-arrow-down cursor-pointer;
}

.mat-select-panel {
    border-radius: 0 !important;
}

.mat-selected {
    background-color: #FFF000 !important;

    .mat-option-text {
        color: black !important;
    }
};

.mat-option {
    .mat-option-text {
        @apply text-gsc-grey-light border-b-2 border-gsc-grey;
    }

    &:last-child > .mat-option-text{
        @apply border-none;
    }
}


// Dialog
.mat-dialog-container {
    border-radius: 0.8rem !important;
    padding: 2.5rem !important;
    text-align: center;
    overflow: visible !important;
}

.mat-dialog-title {
    margin: 0 !important;
    font-weight: bold;
}

.mat-dialog-content {
    max-height: 6rem !important;
    margin-top: 1.25rem !important;
    @apply h-fit;
}

.mat-dialog-content.no-gap {
    max-height: 6rem !important;
    margin-top: 0 !important;
    @apply h-fit;
}

.mat-dialog-actions {
    margin-top: 1.25rem !important;
    @apply p-0;
}

.scrollable-backdrop ~ .cdk-global-overlay-wrapper {
    @apply overflow-y-auto pointer-events-auto;
}

// Accordion
.mat-accordion, .mat-expansion-panel {
    @apply bg-transparent;

    .mat-expansion-panel-body {
        @apply px-0 md:px-6;
    }

    .mat-expansion-panel:last-of-type {
        border-radius: 0 !important;
    }

    .mat-expansion-panel-header {
        @apply px-0 md:px-3 py-5 h-fit;

        &:not(.mat-expanded) {
            @apply border-b-2 border-divider-grey;
        }

        .mat-expansion-panel-header-title {
            @apply m-0 justify-between;
        }

        &.cdk-program-focused:not([aria-disabled=true]) {
            @apply bg-transparent;
        }
    }

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover {
        @apply bg-transparent;
    }

    .mat-expanded {
        & .mat-expansion-panel-header {
            @apply pb-0;
        }

        & .mat-expansion-panel-content {
            @apply border-b-2 border-divider-grey;
        }

        & .mat-expansion-panel-header-title {
            @apply text-gsc-main-yellow;

            &.aurum-title {
                @apply text-gsc-aurum-yellow;
            }

            &.velvet-title {
                @apply text-gsc-velvet-orange;
            }
        }
    }
}

// SideNav
.mat-drawer-container, .mat-drawer-content {
    // override overflow to enable sticky position
    overflow: visible !important;
}

@layer components {
    .cdk-overlay-blur-backdrop {
        @apply bg-white/20 backdrop-blur-sm;
    }

    .info-dialog, .loading-dialog {
        @apply w-full md:w-max md:min-w-[24rem] max-w-sm md:max-w-md lg:max-w-lg max-h-[20rem] m-4;
    }

    .info-dialog {
        position: relative !important;

        &-icon {
            @apply gsc-icon-xl relative left-2/4 -translate-x-2/4 cursor-default bottom-[5rem] mb-[-4rem];
        }
    }

    .delete-dialog, .loading-dialog {
        @apply w-full md:w-max md:min-w-[24rem] max-w-sm md:max-w-md lg:max-w-lg max-h-[20rem] m-4;
    }

    .delete-dialog {
        @apply w-full md:w-max md:min-w-[50rem] max-w-sm md:max-w-md lg:max-w-lg max-h-[20rem] m-4;
    }

    .delete-dialog {
        position: relative !important;

        &-icon {
            @apply gsc-icon-xl relative left-2/4 -translate-x-2/4 cursor-default bottom-[5rem] mb-[-4rem];
        }
    }

    .loading-dialog {
        @apply xl:max-w-lg;

        .mat-dialog-container {
            padding: 1.5rem !important;
            border-radius: 0.5rem !important;
        }
    }

    .image-dialog {
        position: relative !important;
        @apply h-fit w-fit;

        .mat-dialog-container {
            padding: 1rem !important;
        }
    }

    .qr-dialog {
        position: relative !important;
        @apply h-fit w-fit pt-[1.875rem] md:pt-[3.125rem];

        .mat-dialog-container {
            @screen md {
                padding: 4rem 6rem 3rem 6rem !important;
            }

            padding: 2.854rem 2.625rem 1.875rem 2.854rem !important;
        }

        .mat-dialog-actions {
            margin: 0 !important;
            @apply p-0 min-h-fit;
        }
    }

    .profile-qr-dialog {
        position: relative !important;
        @apply h-fit w-fit pt-[1.875rem] md:pt-[3.125rem];

        .mat-dialog-container {
            @screen md {
                padding: 2.375rem 3.688rem !important;
            }

            padding: 2.854rem 2.625rem 1.875rem 2.854rem !important;
        }

        .mat-dialog-actions {
            margin: 0 !important;
            @apply p-0 min-h-fit;
        }
    }

    .showtime-dialog {
        position: relative !important;
        padding-top: 3rem !important;
        height: fit-content !important;

        @screen md {
            padding-top: 7rem !important;
        }

        &-poster {
            @apply absolute left-2/4 -translate-x-2/4 top-0;
        }

        .mat-dialog-container {
            @apply bg-black w-full xs:w-[25rem] sm:w-[30rem] md:w-[36.125rem];
            padding: 2.188rem 1.75rem !important;
        }

        .mat-dialog-content {
            max-height: fit-content !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            padding: 2.5rem 0 0 0 !important;
        }

        .mat-dialog-actions {
            @apply mb-0;
        }
    }

    .product-highlight-dialog {
        position: relative !important;
        // padding-top: 3rem !important;
        height: fit-content !important;

        // @screen md {
        //     margin-top: 1rem !important;
        // }

        .mat-dialog-container {
            @apply w-full xs:w-[15rem] sm:w-[20rem] md:w-[29.875rem] h-full xs:h-[15rem] sm:h-[20rem] md:h-[29.875rem];
            @apply md:mt-4;
            padding: 0 !important;
            // padding: 2.188rem 1.75rem !important;
        }

        .mat-dialog-content {
            max-height: fit-content !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-top: 0 !important;
            padding: 0 !important;
            margin: 0 !important;
            // padding: 2.5rem 0 0 0 !important;
        }
    }

    .product-highlight-dialog .cdk-global-overlay-wrapper {
        align-items: start !important;
    }

    .rewards-redemption-dialog {
        .mat-dialog-container {
            background-color: black;
            padding: 0 0 24px 0 !important;
        }
        
        .mat-dialog-content {
            @apply w-full xs:w-[25rem] md:w-[50rem];
            margin: 0 !important;
            padding: 0 !important;
            max-height: 20rem !important;
        }
    }

    .seat-type-dialog {
        position: relative !important;
        padding-top: 3rem !important;
        height: fit-content !important;

        @screen md {
            padding-top: 7rem !important;
        }

        .mat-dialog-container {
            @apply bg-black w-full xs:w-[25rem] sm:w-[30rem] md:w-[36.125rem] lg:w-[60rem] mb-[3rem] md:mb-[6rem];
            padding: 0 !important;
        }

        .mat-dialog-content {
            max-height: fit-content !important;
            margin-right: 0 !important;
            margin-top: 0 !important;
        }

        .mat-dialog-actions {
            @apply mb-0;
        }
    }

    .single-seat-type-dialog {
        position: relative !important;
        padding-top: 3rem !important;
        height: fit-content !important;

        @screen md {
            padding-top: 7rem !important;
        }

        .mat-dialog-container {
            @apply bg-black w-full xs:w-[25rem] sm:w-[30rem] md:w-[30rem] lg:w-[30rem] mb-[3rem] md:mb-[6rem];
            padding: 0 !important;
        }

        .mat-dialog-content {
            max-height: fit-content !important;
            margin-right: 0 !important;
            margin-top: 0 !important;
        }

        .mat-dialog-actions {
            @apply mb-0;
        }
    }

    .combo-variation-dialog {
        .mat-dialog-container {
            padding: 1.5rem 1rem !important;

            @screen md {
                padding: 1.5rem 2rem !important;
            }

            @apply bg-black text-white w-full md:w-[32.688rem];
        }

        .mat-dialog-actions {
            padding: 0 !important;
            margin: 0 !important;
        }

        .mat-dialog-content {
            max-height: 65vh !important;
        }
    }

    .add-fav-cinema-dialog {
        position: relative !important;

        .mat-dialog-container {
            padding: 1.625rem !important;
            border-radius: 1.25rem !important;
            @apply bg-black text-white w-full xs:w-[21.438rem] sm:w-[32.688rem];
        }

        .mat-dialog-actions {
            padding: 0 !important;
            margin: 0 !important;
        }

        .mat-dialog-content {
            height: 50vh !important;
            max-height: 50vh !important;
        }
    }

    .region-filter-dialog {
        .mat-dialog-title {
            @apply font-montMedium text-base;
        }

        .mat-dialog-content {
            @apply text-start;
        }

        .mat-dialog-container {
            min-width: 278px !important;
            padding: 1.625rem .5rem !important;
        }

        .mat-dialog-content {
            max-height: initial !important;
        }
    }
}

.safety-dialog {
    max-width: 90%;
}

.safety-dialog > mat-dialog-container {
    padding: 0rem !important;
    padding-top: 1rem !important;
    width: 500px;
    overflow: hidden !important;
}

.safety-dialog  mat-dialog-content {
    max-height: 250px !important;
    margin: 1rem !important;
}

.safety-dialog .mat-dialog-actions {
    @apply p-0 m-0 min-h-fit;
}

@layer components{
    .banner-details {
        @apply font-poppinsRegular text-[black] md:text-[1rem] text-[0.75rem];
    }

    .velvet-banner-details{
        @apply font-poppinsRegular text-gsc-velvet-orange md:text-[1rem] text-[0.75rem];
    }

    .velvet-banner-details-info{
        @apply font-poppinsRegular text-white md:text-[1rem] text-[0.75rem];
    }

    .banner-info{
        @apply font-poppinsRegular text-[black] flex items-center gap-[0.375rem] md:text-[1rem] text-[0.75rem];

        .banner-icons {
            @apply md:w-[1.875rem] md:h-[1.875rem] w-[1.25rem] h-[1.25rem];
        }
    }

    .seat-ticket-title {
        @apply text-gsc-grey font-montRegular text-[18px];
    }


    .line {
        @apply w-[2px] h-[2px] rounded-[50%] bg-[black] mx-2;
    }

    .justification {
        @apply md:justify-start;
    }
}

canvas {
    border: 1px solid #CCCCCC;
}

.qr-img {
    .qrcode{
        display: flex;
        justify-content: center;
    }
    canvas{
        border: 0px solid #CCCCCC;
    }

}
@layer components {
    .dial-code-select {
        &.mat-select-panel {
            border-radius: 6px !important;
        }

        .mat-select-trigger {
            @apply h-full;
        }

        .mat-select-arrow-wrapper {
            height: 18px !important;
            width: 18px !important;
        }

        .mat-select-arrow {
            height: 18px !important;
            width: 18px !important;
            opacity: 1 !important;
        }
    }
}

@layer components {
    .account-menu {
        min-height: fit-content !important;

        .mat-menu-content {
            @apply px-3;
        }
    }
}

mat-sidenav{
    width: 100%;
    height: fit-content;
    background-color: #000000 !important;
}
.country-dropdown{
    margin-left: 0.5rem;
    margin-top: 2.5rem !important;
    border-radius: 6px !important;

    .mat-select-field-flex{
        padding: 13px;
    }

    mat-option{
        padding: 0 !important;
    }

    .mat-option .mat-option-text{
        border-color: #E5e5e5 !important;
        --tw-border-opacity: 0.3 !important;
    }
}

.country-form{
    .mat-form-field-wrapper{
        padding: 0 !important;
        margin: 0 !important;
        border: 0 !important;
    }

    .mat-form-field-infix{
        padding: 17px 0px 1px 10px !important;
        margin: 0 !important;
        border: 0 !important;
        width: 100px;
    }

    .mat-form-field-suffix{
        position: relative;
        bottom: 0.37rem;
        padding-right: 5px;
    }
}

.profession-dropdown{
    margin-left: 0.5rem;
    margin-top: 2.5rem !important;
    border-radius: 6px !important;

    .mat-select-field-flex{
        padding: 13px;
    }

    mat-option{
        padding: 0 !important;
    }

    .mat-option .mat-option-text{
        border-color: #E5e5e5 !important;
        --tw-border-opacity: 0.3 !important;
    }
}


.fnb-dropdown{
    margin-left: 0.5rem;
    margin-top: 3rem !important;
    border-radius: 6px !important;

    .mat-select-field-flex{
        padding: 13px;
    }

    mat-option{
        padding: 0 !important;
    }

    .mat-option .mat-option-text{
        border-color: #E5e5e5 !important;
        --tw-border-opacity: 0.3 !important;
    }
}

.fnb-dropdown.mat-select-panel {
    min-width: calc(100% + 39.5px) !important;
}

.mat-form-field-invalid .mat-input-element{
    caret-color: white;
}

.profession-form{
    .mat-form-field-wrapper{
        padding: 0 !important;
        margin: 0 !important;
        border-radius: 6px;
    }

    .mat-form-field-infix{
        padding: 18px 0px 18px 10px !important;
        margin: 0 !important;
        border: 0 !important;
    }

    .mat-form-field-suffix{
        position: relative;
        bottom: -0.3rem;
        padding-right: 5px;
    }

    .mat-select-value-text{
        @apply font-poppinsRegular text-[1rem];
    }

    .mat-form-field-label-wrapper label mat-label{
        position: relative;
        top: 0.5rem;
        left: 0.6rem;
        @apply font-poppinsRegular text-[1rem];
    }
}

.dob-form{
    .mat-form-field-wrapper{
        padding: 0 !important;
        margin: 0 !important;
        border-radius: 6px;
    }

    .mat-form-field-infix{
        padding: 16px 0px 15px 10px !important;
        margin: 0 !important;
        border: 0 !important;
        display: flex;

        input{
            @apply font-poppinsRegular;
        }

        .dob-placeholder{
            width: 120%;
            color: #707070;
            background-color: black;
            @apply font-montRegular cursor-pointer;
        }

        .dob-placeholder:focus{
            outline: none;
        }
    }

    .mat-form-field-suffix{
        position: relative;
        bottom: -0.4rem;
        padding-right: 8px;
    }

    .mat-select-value-text{
        @apply font-montRegular text-[14px];
    }

    .mat-form-field-label-wrapper label mat-label{
        position: relative;
        top: 0.5rem;
        left: 0.6rem;
        @apply font-poppinsRegular text-[14px];
    }
}

.mat-calendar-body-label{
    color: white !important;
}

button:focus,
button:active:focus,
button.active:focus {
    outline: none !important;
    outline-style: none !important;
}

.mat-calendar-previous-button, .mat-calendar-next-button {
    position: relative;
    color: black !important;
}

.mat-calendar-controls{
    @apply text-black;
}

.mat-button-wrapper{
   @apply font-montSemiBold text-[18px];
}

.mat-calendar-arrow{
    display: none !important;
}

.mat-calendar-table-header, .mat-calendar-body-label {
    @apply text-gsc-grey font-montRegular;
}

.mat-calendar-table-header-divider{
    display: none !important;
}

.mat-calendar-body-cell-content{
    @apply text-black font-poppinsRegular;
}

.mat-calendar{
    background-color: white;
    border-radius: 12px;
}

.mat-datepicker-content{
    margin-top: 0.75rem;
}

.mat-datepicker-toggle{
    display: none;
}

.password-form{
    input {
        caret-color: white;
    }

    .mat-form-field-wrapper{
        padding: 0 !important;
        margin: 0 !important;
    }

    .mat-form-field-infix{
        padding: 16px 0px 15px 10px !important;
        margin: 0 !important;
        border: 0 !important;
    }

    .mat-form-field-suffix{
        top: 0.3rem;
        padding-right: 0.5rem;
    }
}

.gender-radio{
    .mat-radio-outer-circle{
        border-color: #FFFFFF99 !important;
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
        border-color: #FFF000 !important;
    }

    .mat-radio-button.mat-accent .mat-radio-inner-circle{
        border-color: #FFF000 !important;
    }

    .mat-radio-ripple {
        display: none;
    }
}

input:focus{
    outline: none;
}

form {
    .mat-form-field-underline {
        display: none;
    }

    .mat-select-arrow{
        opacity: 0 !important;
    }

    .mat-form-field-appearance-outline.mat-form-field-disabled
    {
        .mat-form-field-outline{
            opacity: 0 !important;
        }
    }

    .mat-select-arrow-wrapper{
        width: 0 !important;
        height: 0 !important;
    }
}

.account-menu {
    .mat-menu-content:not(:empty) {
        @apply p-0;
    }
}

@layer components {
    .profile-checkbox {
        &-sm .mat-checkbox-inner-container {
            height: 14px !important;
            width: 14px !important;
        }
        .mat-checkbox-inner-container {
            height: 18px !important;
            width: 18px !important;
        }
        &-sm .mat-checkbox-label, & .mat-checkbox-label {
            font-size: 14px !important;
        }
    }

    .mat-checkbox.mat-warn .mat-checkbox-checkmark-path {
        stroke: #FDB933 !important;
    }

    .err-text{
        @apply text-[red] md:text-[0.625rem] text-[0.563rem] font-montRegular;
    }

    .err-form-text{
        @apply text-[red] lg:text-[0.625rem] text-[0.563rem] font-montRegular;
    }

    .input-normal{
        @apply border-gsc-grey-lighter;
    }

    .input-invalid{
        @apply border-[red];
    }

    .input-focus{
      @apply focus:border-gsc-main-yellow;
    }

    .input-yellow {
        @apply border-gsc-main-yellow;
    }

    .password-focus.mat-focused {
        @apply border-gsc-main-yellow;
    }

    .password-focus {
        @apply border-gsc-grey-lighter;
    }
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: #AAAAAA;
}

.confirm-btn{
    @apply z-[100] bg-gsc-grey-dark fixed bottom-0 md:text-[1rem] text-[0.875rem];

    .confirm-sec{
         @apply font-montSemiBold cursor-pointer;
    }
 }

.delete-account-reason {
    .mat-list-item-content {
        padding: 0 !important;
    }
}

.seat-selection {
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.drag-scroll-content {
    width: 100% !important;
    height: 100% !important;
}

.no-expansion {
    .mat-expansion-panel-header {
        display: none !important;
        visibility: hidden !important;
    }
    .mat-expansion-panel-content {
        display: none !important;
        visibility: hidden !important;
    }
 }


 .invoice {
    .img-responsive {
        width: 100% !important;
    }
}

.itm-quantity-hall-booking {
    @apply mx-[0.875rem] w-[4.125rem] md:h-[1.875rem] h-[1.5rem] flex justify-center items-center font-montSemiBold text-white;
}

.combo-select-icon {
    @apply w-[1.875rem] h-[1.875rem] cursor-pointer;
}

.past-ticket {
    border-radius: 0.75rem;
    background-color: #383838 !important;
}

.upcoming-ticket {
    @apply text-black;
}

.active-ticket {
    border-radius: 0.75rem;
    background-color: white !important;
}

.img-style{
    @apply md:w-[30px] md:h-[30px] md:min-h-[30px] md:min-w-[30px] w-[20px] h-[20px] min-h-[20px] min-w-[20px];
}
